import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import Layout from "../components/layout"
import Head from "../components/head"
import pageNotFoundSvg from "../assets/page-not-found.svg"

const HideOnScrollMargin = styled.main`
  margin-top: 56px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-top: 64px;
  }
`

const HeroWrap = styled.div`
  padding: ${props => props.theme.spacing(7, 0, 4)};

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding: ${props => props.theme.spacing(10, 0, 7)};
  }

  .hero-gird-container {
    display: grid;
    grid-gap: ${props => props.theme.spacing(6)}px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;

    .bottom-margin {
      margin-bottom: ${props => props.theme.spacing(6)}px;
    }

    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .hero-grid-item-image {
    text-align: center;

    .hero-image {
      width: 100%;
    }
  }
`

const NotFoundPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <HideOnScrollMargin>
        <HeroWrap>
          <Container maxWidth="lg">
            <div className="hero-gird-container">
              <div>
                <div className="bottom-margin">
                  <Typography
                    component="h1"
                    variant="h2"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                  >
                    Oops!
                  </Typography>
                  <Typography
                    variant="h5"
                    align="left"
                    color="textSecondary"
                    paragraph
                  >
                    We can't seem to find the page you're looking for.
                  </Typography>
                </div>
              </div>

              <div className="hero-grid-item-image">
                <img
                  src={pageNotFoundSvg}
                  alt="Page not found"
                  className="hero-image"
                />
              </div>
            </div>
          </Container>
        </HeroWrap>
      </HideOnScrollMargin>
    </Layout>
  )
}

export default NotFoundPage
